<template>
    <div>
        <v-dialog v-model="dialog" width="600">
            <v-card>
                <bar-title-dialog title="Consumo Interno " @close="dialog = false"></bar-title-dialog>

                <v-card-text>
                    <v-row no-gutters>
                        <v-col>
                            <v-text-field v-model="editedItem.consumer" :error-messages="errors.consumer"
                                @change="errors.consumer = null" outlined label="Consumidor"></v-text-field>

                            <!-- <v-select outlined  :items="['azul','verde']" label="Consumidor"></v-select> -->
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col>
                            <v-select v-model="editedItem.product_id" @change="calcTotal" outlined :items="products" :error-messages="errors.product_id"
                                item-value="id" item-text="description" label="Produto"></v-select>
                        </v-col>
                    </v-row>
                    <v-row no-gutters>
                        <v-col class="mx-2">
                            <vuetify-money v-model="editedItem.quantity":error-messages="errors.quantity" @input="calcTotal" class="" outlined
                                label="Quantidade" :options="{ locale: 'pt-BR', length: 11, precision: 0 }" @change="errors.quantity=null"/>
                        </v-col>
                        <v-col class="mx-2">
                            <vuetify-money v-model="total" disabled class="" outlined label="Total:"
                                :options="{ locale: 'pt-BR', length: 11, precision: 2 }" />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-row>
                        <v-col class="text-right">
                            <v-btn x-large text class="mr-2" @click="dialog = false">fechar</v-btn>
                            <v-btn x-large color="primary" :loading="loading" :disabled="loading"
                                @click="sendInternConsumption()">Salvar</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import {
    mapActions
} from 'vuex'

export default {
    props: {
        value: {
            type: Boolean
        }
    },
    components: {
        BarTitleDialog,
    },

    data: () => ({
        products: [],
        total: 0,
        loading: false,
        dialog: false,
        editedItem: {
            product_id: '',
            consumer: '',
            quantity: '0',
        },
        editedDefault: {
            product_id: '',
            consumer: '',
            quantity: '0',
        },
        errors: {
            consumer: null,
            quantity: null,
            product_id: null
        }
    }),

    watch: {
        value(val) {
            this.dialog = val
            if (val) {
                //carrega products
                this.getProducts().then((response) => {
                    this.products = response.data
                }).finally()
            }
        },
        dialog(val) {
            this.$emit('input', val)
        }
    },
    methods: {
        ...mapActions('cash', ['internConsumption', 'getProducts']),

        sendInternConsumption() {
            if (!this.validate()) {
                return
            }

            this.loading = true
            this.internConsumption(this.editedItem).then((response) => {
                this.$toast.success('registrado com sucesso')
                this.dialog = false
                this.editedItem = { ...this.editedDefault }
            }).finally(() => {
                this.loading = false
            })
        },

        calcTotal() {
            this.errors.quantity = null
            this.errors.product_id = null
            let product = this.products.find((product) => product.id == this.editedItem.product_id)
            this.total = this.editedItem.quantity * product.unit_cost
            
        },
        validate() {

            if (this.editedItem.consumer == null || this.editedItem.consumer == '') {
                this.errors.consumer = 'Campo obrigatório'
                return false
            }
            if (this.editedItem.quantity == null || this.editedItem.quantity <= 0) {
                this.errors.quantity = 'Campo obrigatório'
                return false
            }
            if (this.editedItem.product_id == null || this.editedItem.product_id == '') {
                this.errors.product_id = 'Campo obrigatório'
                return false
            }
            return true
        }
    },


}
</script>
